import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import GallaryPage from "./components/GalleryPage";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import classes from "./App.module.css";

const App = () => {
  return (
    <Router>
      <div className={classes.app}>
        <NavBar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/gallery" component={GallaryPage} />
          <Route exact path="/contact" component={Contact} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
