import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={classes.footer}>
      <a
        href="https://www.instagram.com/jessiesglassworld2021/"
        target="_blank"
        rel="noreferrer"
      >
        <img src={`${process.env.PUBLIC_URL}/ig.png`} alt="instagram-icon" />
      </a>
      <a
        href="https://www.etsy.com/shop/Jessiesglassworld"
        target="_blank"
        rel="noreferrer"
      >
        <img src={`${process.env.PUBLIC_URL}/etsy.png`} alt="etsy-icon" />
      </a>
      <a
        href="https://www.facebook.com/Jessiesglassworld-102464641674626"
        target="_blank"
        rel="noreferrer"
      >
        <img src={`${process.env.PUBLIC_URL}/fb.png`} alt="facebook-icon" />
      </a>
    </footer>
  );
};

export default Footer;
