import classes from "./Contact.module.css";

const About = () => {
  return (
    <div className={classes.contact}>
      <p>
        I love to create one-of-a-kind works of art for my clients. Reach out
        and let's get started on something special!
      </p>
      <iframe
        src="https://maps.google.com/maps?q=2238%20Bonds%20Pike,%20Stamping%20Ground%20KY%2040379&t=&z=13&ie=UTF8&iwloc=&output=embed"
        frameBorder="0"
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
        title="map"
      ></iframe>

      <p>Phone</p>
      <p className={classes.contactinfo}>(859)475-4731</p>

      <p>Email</p>
      <p className={classes.contactinfo}>jessie@jessiesglassworld.com</p>

      <p>Address</p>
      <p className={classes.contactinfo}>2238 Bonds Pike, Stamping Ground KY 40379</p>
    </div>
  );
};

export default About;
