import { Fragment } from "react/cjs/react.production.min";
import classes from "./Home.module.css";

const Home = () => {
  return (
    <Fragment>
      <div className={classes.about}>
        <img src={`${process.env.PUBLIC_URL}/profile.jpg`} alt="profile-pic" />
        <p>
          Hello! Welcome to Jessie's Glass World. I'm Jessie, and this is my
          granddaughter and little glass helper, Zoë.
        </p>
        <p>
          Creating stained glass art has been my full time occupation since
          2016. With a huge array of in-stock glass to choose from, and using
          professional stained glass patterning software, I can transform your
          vision into a stunning, one-of-a-kind work of art. Reach out today
          and let's get started working on something truly special!
        </p>
        <p>
          Looking for a unique gift, fast? No problem! Just click the Etsy link
          below. I almost always have pieces in stock, ready to ship.
        </p>
        <p>
          Thanks again for visiting Jessie's Glass World. Make sure to follow me
          on Facebook and Instagram!
        </p>
      </div>
    </Fragment>
  );
};

export default Home;
