import Gallery from "react-photo-gallery";
import classes from "./GalleryPage.module.css";

const GalleryPage = () => {

  const photos = [
    {
      src: "http://jessiesglassworld.com/assets/3dstar.jpg",
      width: 1,
      height: 1,
    },
    {
      src: "http://jessiesglassworld.com/assets/cardinals.jpg",
      width: 4,
      height: 3,
    },
    {
      src: "http://jessiesglassworld.com/assets/cuteowl.jpg",
      width: 1,
      height: 2,
    },
    {
      src: "http://jessiesglassworld.com/assets/blue_ornament.jpg",
      width: 1,
      height: 1,
    },
    {
      src: "http://jessiesglassworld.com/assets/painted_bat.jpg",
      width: 1,
      height: 1,
    },
    {
      src: "http://jessiesglassworld.com/assets/pride_heart.jpg",
      width: 2,
      height: 3,
    },
    {
      src: "http://jessiesglassworld.com/assets/sunshine.jpg",
      width: 3,
      height: 2,
    },
    {
      src: "http://jessiesglassworld.com/assets/topper.jpg",
      width: 1,
      height: 1,
    },
    {
      src: "http://jessiesglassworld.com/assets/birdhouse.jpg",
      width: 2,
      height: 3,
    },
  ];

  return (
    <div className={classes.gallery}>
      <Gallery photos={photos} />
    </div>
  );
};

export default GalleryPage;
